<template>
  <vx-card title="Zona de pagos">
    <template slot="actions">
      <div>
        <vs-button
            v-if="$can('create', 'payment')"
            size="small" color="success" type="border" icon-pack="feather" icon="icon-file-plus"
            @click="createEntry()"
        >
          Nuevo
        </vs-button>
      </div>
    </template>

    <div v-if="$can('list', 'payment')" class="vx-row">
      <div class="vx-col md:w-1/4 w-full mt-10">
        <div class="m-3 mt-10">
          <filter-groups :model="queryStrings" :filter-groups="filterGroups" @update="getEntries"/>
          <vs-divider/>
          <div>
            <ul class="centerx">
              <div class="mt-4"><b>Papelera de reciclaje</b></div>
              <li>
                <vs-checkbox v-model="recycleBin" class="mt-4" @change="getEntries">Si</vs-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="vx-col md:w-3/4 w-full">
        <vs-table max-items="10" pagination search stripe :data="entries" :hoverFlat="true">
          <template slot="thead">
            <vs-th sort-key="name">Nombre</vs-th>
            <vs-th sort-key="category">Categoría</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td>
                <p class="font-medium">{{ record.name }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.category.name }}</p>
              </vs-td>
              <vs-td>
                <div class="flex" v-if="!recycleBin">
                  <vx-tooltip text="Editar">
                    <vs-button
                      :disabled="!($can('retrieve', 'payment') || $can('update', 'payment'))"
                      radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                      @click="retrieveEntry(record.id)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Publicar">
                    <vs-button
                      radius size="large" :color="record.in_production ? 'dark' : 'success'" type="flat" icon-pack="feather" icon="icon-send"
                      :disabled="record.in_production"
                      @click="confirmCommit(record)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Eliminar">
                    <vs-button
                      :disabled="!$can('delete', 'payment')"
                      radius size="large" color="warning" type="flat" icon-pack="feather" icon="icon-trash-2"
                      @click="confirmDelete(record)"
                    />
                  </vx-tooltip>
                </div>
                <div class="flex" v-else>
                  <vx-tooltip text="Restaurar">
                    <vs-button
                        :disabled="!($can('create', 'payment') || $can('update', 'payment'))"
                        radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-refresh-cw"
                        @click="confirmRestore(record)" @click.stop
                    />
                  </vx-tooltip>
                </div>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import FilterGroups from '@/components/filter-groups/FilterGroups';
  import * as notificationMixins from "../../utils/notificationMixins";

  let notifier = new Notifier();
  const ENTRIES_CLIENT = new EntriesClient("payments");
  const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("payments");

  export default {
    data() {
      return {
        entries: [],
        queryStrings: {},
        filterGroups: [
          {
            name: "category_id",
            label: "Categoría",
            filters: []
          },
        ],
        recycleBin: false,
      };
    },

    mounted() {
      this.$vs.loading();
      this.getCategories()
          .then(() => {
            this.getEntries();
          })
          .then(() => {
            this.$vs.loading.close();
          });
    },

    methods: {
      async getEntries(showLoading = true) {
        this.entries = [];
        if (showLoading) this.$vs.loading();
        await ENTRIES_CLIENT.all({fromRecycleBin: this.recycleBin, queryStrings: this.queryStrings})
            .then(response => {
              this.entries = response.data;
              if (showLoading) this.$vs.loading.close();
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
      },

      async getCategories(showLoading = true) {
        if (showLoading) this.$vs.loading();
        await ATTRIBUTE_OPTIONS_CLIENT.categories({queryStrings: {representation_type: "simple"}})
            .then(response => {
              response.data.forEach(element => {
                this.filterGroups[0].filters.push({
                  value: element.id,
                  label: element.name
                });
              });
              if (showLoading) this.$vs.loading.close();
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
      },

      async deleteEntry(entryId) {
        if (entryId !== undefined && entryId !== null && entryId !== "") {
          this.$vs.loading();
          await ENTRIES_CLIENT.delete({pathParameters: {entryId: entryId}})
              .then(response => {
                notifier.notification("deleted");
                this.getEntries()
                    .then(() => {
                      this.$vs.loading.close();
                    });
              }).catch(error => {
                this.$vs.loading.close();
                notifier.alertMessage("error");
              });
        }
      },

      confirmDelete(entry) {
        notifier.deleteMessage().then(result => {
          if (result.value) {
            this.deleteEntry(entry.id);
          }
        });
      },

      confirmCommit(entry) {
        notificationMixins.CommitEntryNotification.fire()
          .then(result => {
            if (result.value) {
              if (entry.id !== null && entry.id !== undefined && entry.id !== "") {
                this.$vs.loading();
                ENTRIES_CLIENT.commit({pathParameters: {entryId: entry.id}})
                  .then(response => {
                    notifier.notification("committed");
                    this.getEntries();
                  })
                  .catch(error => {
                    notifier.alertMessage("error");
                  });
                this.$vs.loading.close();
              }
            }
          });
      },

      confirmRestore(entry) {
        notificationMixins.RestoreEntryNotification.fire()
            .then(result => {
              if (result.value) {
                if (entry.id !== null && entry.id !== undefined && entry.id !== "") {
                  this.$vs.loading();
                  ENTRIES_CLIENT.restore({pathParameters: {entryId: entry.id}})
                      .then(response => {
                        notifier.notification("restored");
                        this.getEntries();
                      })
                      .catch(error => {
                        notifier.alertMessage("error");
                      });
                  this.$vs.loading.close();
                }
              }
            });
      },

      createEntry() {
        this.$router.push({name: "create-payment"});
      },

      retrieveEntry(entryId) {
        this.$router.push({name: "payment", params: {id: entryId}});
      }
    },

    components: {
      'filter-groups': FilterGroups
    }
  };
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>
